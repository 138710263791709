import '@shared/components'
import '@shared/plugins'
import Vue, { VueConstructor } from 'vue'
import navigation from '../mixins/navigation'

export default class Application {
  protected vm: Vue

  constructor(modules: any = []) {
    this.bindListeners()
    this.requireIcons()
    this.vhFix()

    if (!Array.isArray(modules)) {
      modules = [modules]
    }

    modules.push(navigation)

    this.initVue(modules)
  }

  private initVue(modules: VueConstructor[]) {
    this.vm = new Vue({
      mixins: modules,
      data: {},
    })

    this.vm.$mount('#app')
  }

  private bindListeners() {
    window.addEventListener('orientationchange', this.vhFix)
    window.addEventListener('resize', this.vhFix)
  }

  private requireIcons() {
    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = require('!!html-loader!../../img/icons.svg')
    body.insertBefore(div, body.childNodes[0])
  }

  private vhFix() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}
