import Vue from 'vue'
import Component from 'vue-class-component'
import { mixin as clickaway } from 'vue-clickaway'

@Component({
  mixins: [clickaway],
})
class Navigation extends Vue {
  public showNavigation: boolean = false

  public toggleNavigation() {
    this.showNavigation = !this.showNavigation
  }

  public clickOutside() {
    this.showNavigation = false
  }
}

export default Navigation
